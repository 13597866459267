import { useEffect, useState } from "react"; 
import { useSearchParams } from 'react-router-dom'; 
import { BaseModal, InputField, SmallButtonYellow, InvertedSmallButton } from 'component-library' 
import { activateSubscription, login, verifyCode } from "@api/API";
import { useTranslation } from "react-i18next";
import { useUser } from "@context/UserContext";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate"; 
import { useNotification } from "@context/Notification/NotificationContext";
import { EmailCheck } from "@regex/EmailCheck";

type ActivateAccountModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const ActivateAccountModalConstructor = ({ openModal, toggleModal, darkMode = false }: ActivateAccountModalProps) => {
  const { isAuth, isLoading, user, fetchUserDetails } = useUser() as any;
  const { t } = useTranslation() 
  const { notify } = useNotification();
  const customNavigate = useCustomNavigate();
  const [isError, setIsError] = useState(true)
  const [loading, setLoading] = useState(false)
  const [tempCode, setTempCode] = useState('')
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
    confirmEmail: '',
    dob: '',
    subscribe: false,
    terms: false,
    code: '',
    firstName: '',
    lastName: '',
    postal: '',
    parentEmail: ''
  })
  const [searchParams] = useSearchParams();
  const paramCode = searchParams.get('code') || searchParams.get('activation_code');
  const [parentRequired, setParentRequired] = useState(false)

  useEffect(() => {
    if(paramCode){
      let tempcode = paramCode
      setTempCode(tempcode)
      //remove all special characters from string
      tempcode = tempcode.replace(/[^a-zA-Z0-9]/g, '')
      //only use first 6 characters
      tempcode.slice(0, 6)
      setLoginDetails({
        ...loginDetails,
        code: tempcode
      })
    }
  }, [paramCode]) 

  useEffect(() =>{
    if(!isLoading){
      if(!isAuth){
        customNavigate('/inloggen')
        notify(t('loginRequired'), "error")
      }
    }
  }, [isAuth, isLoading])

  useEffect(() => {
    checkDate()
  }, [user, loginDetails])

  // const selectedIsToday = () => {
  //   const [day, month, year] = user.dateBirth.split('-').map(Number);
  //   // const birthDate = new Date(year, month - 1, day);
  //   // const today = new Date();
  //   // return selectedDate.toDateString() === today.toDateString();
  // }

  const checkDate = () => {
    // //check if user is under 16
    const [day, month, year] = loginDetails.dob?.split('-').map(Number);
    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    age < 16 ? setParentRequired(true) : setParentRequired(false)
  }

  useEffect(() =>{
    if(!isLoading){
      if(isAuth){
        updateUserDetails()
      }
    }
  }, [user, isLoading, isAuth])

  const updateUserDetails = () => { 
    setLoginDetails({
      ...loginDetails,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      // postal: user.profile.postal,
      dob: user.dateBirth,
      subscribe: user.optInPreferences?.emailMarketing
    })
  }

  const updateCode = (value: any, state: string) => {
    let tempcode = value
    setTempCode(tempcode)
    //remove all special characters from string, also spaces and dashes
    tempcode = tempcode.replace(/[^a-zA-Z0-9]/g, '')
    //only use first 6 characters
    tempcode = tempcode.slice(0, 6)

    setLoginDetails({
      ...loginDetails,
      [state]: tempcode
    })
  }

  const updateLoginDetails = (value: any, state: string) => {
    setLoginDetails({
      ...loginDetails,
      [state]: value
    })
  } 

  useEffect(() => {
    if(loginDetails?.code?.length > 4){
      checkRegistrationCode()
    }
  }, [loginDetails.code, tempCode])

  const checkRegistrationCode = async () => {
    const response = await verifyCode(loginDetails.code)
    if(response?.status === 200){
      setIsError(false)
      setLoginDetails({
        ...loginDetails,
        firstName: response.data?.data?.schoolMembersObject?.firstName || "",
        lastName: response.data?.data?.schoolMembersObject?.lastName || "",
      })
      // updateLoginDetails(response.data?.data?.schoolMembersObject?.firstName, 'firstName')
      // updateLoginDetails(response.data?.data?.schoolMembersObject?.lastName, 'lastName')
    } else {
      setIsError(true)
    }
  } 

  const checkDisabled = () => {
    if (
      loginDetails.code.length > 0 && 
      isError === false &&
      loginDetails.firstName.length > 0 && 
      loginDetails.lastName.length > 0 && 
      (parentRequired && loginDetails.parentEmail.length > 0 && EmailCheck(loginDetails.parentEmail) || !parentRequired)
      // loginDetails.terms === true 
    ) {
      return false
    } else {
      return true
    }
  }

  const handleActivateAccount = async () => {
    setLoading(true)
    const data = {
      dateOfBirth: loginDetails.dob,
      email: loginDetails.email,
      isSubscriber: String(loginDetails.subscribe),
      postalCode: loginDetails.postal,
      phone: '',
      firstName: loginDetails.firstName,
      lastName: loginDetails.lastName
    } as any
    if(parentRequired){
      data['parentEmail'] = loginDetails.parentEmail
    }
    const response = await activateSubscription(loginDetails.code, data)
    if(response?.status === 200){
      if(!isAuth){
        handleLogin()
      } else
      customNavigate('/account')
    } else {
      console.log('error activating account')
    }
  }

  const handleLogin = async () => {   
    const response = await login(loginDetails.email, loginDetails.password, "")
    if(response.status === 200){
      setUserDetails() 
    } else if (response.status === 202){ //2fa required
      customNavigate('/inloggen')
    } else { 
      console.log('error', response)
    } 
  }

  const setUserDetails = async () => {
    try {
      
      if(fetchUserDetails){
        fetchUserDetails()
      }   

      //wait 100ms for DB to be finished
      setTimeout(() => {
        customNavigate('/lidmaatschap/activeren') 
      }, 100)
      
    } catch (error) {
      console.log('Failed to fetch user details'); 
    }
  };

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      modalTitle={t('activateAccount')}
      modalSubtitle={t('activateAccountSubtitle')}
      darkMode={darkMode}
    >   
      <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
        <InputField
          placeholder={t('activationCode')}
          value={tempCode}
          setState={() => {}}
          type="text"
          error={loginDetails?.code?.length > 0 && isError}
          handleUpdate={updateCode}
          itemKey={'code'}
          darkMode={darkMode}
          errorMsg={t('activationCodeError')}
        />
        {isError &&
          <a className="text-left cursor-pointer underline pt-2" target='_blank' rel='noreferrer' href='https://support.cjp.nl/portal/nl/kb/articles/activeren-digitale-pas-en-cjp-kortingen' >
            {t("needSupport")}
          </a>
        }
        {!isError &&
          <div> 
            <InputField
              placeholder={t('firstName')}
              value={loginDetails.firstName}
              setState={() => {}}
              type="text"
              error={loginDetails?.firstName?.length > 0 && loginDetails?.firstName?.length < 2}
              handleUpdate={updateLoginDetails}
              itemKey={'firstName'}
              darkMode={darkMode}
            />
            <InputField
              placeholder={t('lastName')}
              value={loginDetails.lastName}
              setState={() => {}}
              type="text"
              error={false}
              handleUpdate={updateLoginDetails}
              itemKey={'lastName'}
              darkMode={darkMode}
            />
            {parentRequired &&
              <div className="">
                <InputField
                  placeholder={t("parentEmail")}
                  value={loginDetails.parentEmail}
                  setState={() => {}}
                  type="text"
                  error={loginDetails.parentEmail.length > 0 && !EmailCheck(loginDetails.parentEmail)}
                  handleUpdate={updateLoginDetails}
                  itemKey={'parentEmail'}
                  darkMode={darkMode}
                  errorMsg={t("emailError")}
                />
                <div className="text-sm text-gray-500 pt-3">
                  {t('parentEmailExplanation')}
                </div>
              </div> 
            }
          </div>
        }
      </div> 
      <div className='w-full flex flex-col px-8 mt-8 gap-y-4'>
        <SmallButtonYellow
          text={t('activateButton')}
          onClick={() => handleActivateAccount()}
          disabled={checkDisabled()}
          darkMode={darkMode}
          loading={loading}
        />
        <InvertedSmallButton
          text={t('noCode')}
          onClick={() => customNavigate('/lidmaatschap')}
          darkMode={darkMode}
          // disabled={loginDetails.email.length == 0 || EmailCheck(loginDetails.email)}
        /> 
      </div> 
    </BaseModal>
  )
}