import React, { createContext, useContext, ReactNode, useState } from 'react';
import Notification from './Notification';

interface NotificationContextType {
  notify: (content: string, type: 'success' | 'error' | 'warning' | 'info') => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<{ content: string; type: 'success' | 'error' | 'warning' | 'info'; id: number }[]>([]);
  const [offsets, setOffsets] = useState<number[]>([]);

  const notify = (content: string, type: 'success' | 'error' | 'warning' | 'info') => {
    const id = new Date().getTime(); // Simple unique ID for key prop
    setNotifications((prev) => [...prev, { content, type, id }]);
    setOffsets((prev) => [...prev, 0]);
    setTimeout(() => {
      setNotifications((prev) => prev.filter((notification) => notification.id !== id));
    }, 5000); // Remove notification after 5 seconds
  };

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      {notifications.map((notification, index) => (
        <Notification key={notification.id} content={notification.content} type={notification.type} index={index} />
      ))}
    </NotificationContext.Provider>
  );
};
