import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';   
import { LoginModalConstructor } from '@components/modal/LoginComponentConstructor';
import { CreateAccountModalConstructor } from '@components/modal/CreateAccountModal';
import { EmailPassModalConstructor } from '@components/modal/EmailPassModal';
import { SuccessModalConstructor } from '@components/modal/SuccessModalConstructor';
import { BuySubscriptionModalConstructor } from '@components/modal/BuySubscriptionModal';

import "/node_modules/flag-icons/css/flag-icons.min.css";
import { SetupTwoFactorModalConstructor } from '@components/modal/SetupTwoFactor';
import { SelectOriginModalConstructor } from '@components/modal/SelectOriginModal';
import { useNotification } from '@context/Notification/NotificationContext';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '@components/navigate/useCustomNavigate';
import { ResetEmailModalConstructor } from '@components/mijn/ResetEmailModal';

import colorful_bg from '@assets/colorful_bg.png';

function AuthModal() {
  const { t } = useTranslation();
  const customNavigate = useCustomNavigate();
  const [openModal, setOpenModal] = useState(true);
  const toggleModal = () => setOpenModal(true);
  const { notify } = useNotification();
  const [searchParams] = useSearchParams();
  const { modal } = useParams();
  const origin = searchParams.get('origin') as string;
  const product = searchParams.get('product') as string;

  let errorMsgIndex = 0

  useEffect(() => { 
    const error = searchParams.get('error');
    if(error && errorMsgIndex === 0){
      errorMsgIndex++
      notify(t('error-'+error, { portal: origin }), 'error');
      customNavigate('/inloggen')
    }
  }, []); 

  const darkMode = false;
  
  const renderModal = () => {
    switch (modal) {
      case 'aanmelden':
        return <LoginModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'inloggen':
      case 'login':
        return <EmailPassModalConstructor openModal={openModal} toggleModal={toggleModal} origin={origin} darkMode={darkMode} product={product}/>;
      case 'account-aanmaken':
        return <CreateAccountModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;        
      // case 'success':
      //   return <SuccessModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      // case 'upgrade':
      //   return <BuySubscriptionModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'setup-2fa':
        return <SetupTwoFactorModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'origin':
        return <SelectOriginModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
      case 'email-bevestigen':
      case 'confirm-email':
          return <ResetEmailModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;  
      default:
        return <LoginModalConstructor openModal={openModal} toggleModal={toggleModal} darkMode={darkMode}/>;
    }
  };

  return (
    <div className={`bg-slate-100 min-h-screen ${darkMode ? "dark" : ""}`}>
      {/* <div className='absolute inset-0 bg-cover bg-center z-0' style={{ backgroundImage: 'url(https://cdn.britannica.com/51/194651-050-747F0C18/Interior-National-Gallery-of-Art-Washington-DC.jpg)' }}></div> */}
      <div className='absolute inset-0 bg-cover bg-center z-0' style={{ backgroundImage: `url(${colorful_bg})` }}></div>

      {/* <div className='absolute inset-0 bg-black/60 z-0'></div> */}
      <div className='absolute inset-0 bg-black/40 z-0'></div>
      {openModal &&
        <div className={` w-screen h-screen absolute inset-0 grid place-content-center z-50 ${openModal ? '' : 'pointer-events-none'}`} >
          {renderModal()}
        </div>
      }
    </div>
  );
}

export default AuthModal;
