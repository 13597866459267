import { useEffect, useState } from "react";

import CardImage from '@assets/CardImage.png'
import PointerImg from '@assets/PointerImg.png'

import { BaseModal, InputField, SmallButtonYellow, Checkbox } from 'component-library'

// import { EmailCheck } from './../regex/EmailCheck'
// import { PostalCheck } from "@regex/PostalCheck";
import { useTranslation } from "react-i18next";
import { useUser } from "@context/UserContext";
// import { DropdownMenu } from "./DropdownMenu";

import { checkoutIndividual, getProducts, updateProfile } from '@api/API';
import { useNotification } from "@context/Notification/NotificationContext";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";


type BuySubscriptionModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const BuySubscriptionModalConstructor = ({ openModal, toggleModal, darkMode = false }: BuySubscriptionModalProps) => {
  const { t } = useTranslation()
  const customNavigate = useCustomNavigate();
  const { notify } = useNotification();
  const { isAuth, isLoading, user, fetchUserDetails } = useUser() as any;
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
    confirmEmail: '',
    dateBirth: '',
    subscribe: false,
    code: '',
    firstName: '',
    lastName: '',
    postal: '',
  })
  // const [isError, setIsError] = useState(false)
  // const [availableBanks, setAvailableBanks] = useState([] as any)
  // const [autoRenew, setAutoRenew] = useState<boolean>(true)

  useEffect(() =>{
    if(!isLoading){      
      if(!isAuth){
        customNavigate('/inloggen')
        notify(t('loginRequired'), "error")
      }
    }
  }, [isAuth, isLoading])

  const [optIns, setOptIns] = useState<{
    marketing_emails_optin: boolean;
    terms_optin: boolean;
    age_optin: boolean;
    [key: string]: boolean;
  }>({
    marketing_emails_optin: false,
    terms_optin: false,
    age_optin: false
  })

  // const handleAutoRenew = () => {
  //   setAutoRenew(!autoRenew)
  // }

  const handleOptin = (optin: string) => {
    setOptIns({
      ...optIns,
      [optin]: !optIns[optin]
    })
  }

  const [productId, setProductId] = useState(-1)


  const updateLoginDetails = (value: any, state: string) => {
    setLoginDetails({
      ...loginDetails,
      [state]: value
    })
  }

  useEffect(() => {
    if(isAuth){
      getAllProducts()
    }
  }, [isAuth])

  const getAllProducts = async() => {
    // for now we default to a card with INDIVIDUAL as a name, taking the first card from the available array
    const response = await getProducts()
    if(response.data.success){
      if(response.data.data[0].participant === "INDIVIDUAL")
        setProductId(response.data.data[0].id)
    }
  }

  // useEffect(() => {
  //   checkDate()
  // }, [loginDetails.dateBirth])

  // const checkDate = () => {
  //   //function to check if the date is valid and if the user is no more than 30 years old 
  //   setIsError(true)
  //   const date = new Date(loginDetails.dateBirth);
  //   const today = new Date();
  //   let age = today.getFullYear() - date.getFullYear();
  //   const month = today.getMonth() - date.getMonth();
  //   if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
  //     age--;
  //   }
  //   if (age < 30 && age > 12) {
  //     setIsError(false) 
  //   } 
  // }

  useEffect(() =>{
    if(user){
      updateUserDetails()
    }
  }, [user])

  const updateUserDetails = () => {
    isAuth && 
      setLoginDetails({
        ...loginDetails,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        postal: user.address?.zip || "",
        dateBirth: user.dateBirth,
        subscribe: user.optInPreferences?.emailMarketing || false
      })
  }

  const checkDisabled = () => {
    if (
        loginDetails.firstName.length == 0 ||
        loginDetails.lastName.length == 0 ||  
        !optIns.terms_optin ||
        !optIns.age_optin
      ) {
      return true
    } else {
      return false
    }
  } 

  const handleBuySubscription = async() => {
    setIsLoadingPage(true)
    const buySub = await handleUpdateAccount()
    if(buySub){
      const response = await checkoutIndividual(productId, true)
      if(response.data.success === true){
        window.location.href = response.data.data.redirect_url || ""
      }
    }
  }

  // const formatDateToDDMMYYYY = (date: Date): string => {
  //   let day = date.getDate().toString().padStart(2, '0'); 
  //   let month = (date.getMonth() + 1).toString().padStart(2, '0'); 
  //   let year = date.getFullYear();
  //   return `${year}-${month}-${day}`;
  // };

  // const saveUserDetails = async() => {
  //   let data = {
  //     email: loginDetails.email,
  //     first_name: loginDetails.firstName,
  //     last_name: loginDetails.lastName,
  //     date_birth: formatDateToDDMMYYYY(new Date(loginDetails.dateBirth)),
  //     individual_profile: {
  //       address: {
  //         zip_code: loginDetails.postal
  //       }
  //     },
  //     marketing_emails_optin: loginDetails.subscribe
  //   }
  //   const response = await updateUser(data)
  //   if(response.status === 200){
  //     return true
  //   }
  //   else {
  //     notify(t('saveUserError'), 'error');
  //     return false
  //   }
  // }

  const handleUpdateAccount = async() => {
    const response = await updateProfile(loginDetails)
    if(response.status === 200){
      notify("Account gegevens zijn opgeslagen.", "success")
      return true
    } 
    else {
      notify("Er is een fout opgetreden.", "error")
      return false
    }
  } 
  return (
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
    > 
      <div className="gap-y-5 flex flex-col flex-1">
        <div
          className={`card-mobile w-full animate ${darkMode ? "bg-white hover:bg-white/90" : "bg-slate-100 hover:bg-slate-200"} rounded-lg p-4 flex flex-col sm:hidden relative cursor-pointer mt-6`}
          onClick={() => customNavigate('/lidmaatschap/kopen')}
        >
        <div className="bg-main-yellow aspect-square absolute -top-4 left-2 p-3 text-black/80 font-bold font-formula tracking-wider flex items-center text-xl">
          €10.-
        </div>
        <div className="flex w-full items-center justify-center sm:justify-start">
          <img src={CardImage} alt="gratisImg" className="min-w-24 aspect-[3/5] sm:mx-0 mx-auto" />

          <div className="hidden sm:block w-[calc(100%-9rem)] pl-10 text-black/80">
            <div className="uppercase font-bold font-formula tracking-wide text-4xl">
              {/* {t('becomeMember')} */}
            </div>
            <div className="uppercase font-bold font-formula text-2xl tracking-wide mb-4">
              {t('becomeMemberSubtitle')}
            </div>
            <div className="flex gap-x-2">
              <img 
                src={PointerImg}
                className="w-4 h-4 mt-1"
              />
              {t('becomeMemberPerk1')}
            </div> 
            <div className="flex gap-x-2">
              <img 
                src={PointerImg}
                className="w-4 h-4 mt-1"
              />
              {t('becomeMemberPerk2')}
            </div> 
          </div>
        </div>
        <div className="block sm:hidden w-full text-black/80 mt-4">
          <div className="uppercase font-bold font-formula tracking-wide text-4xl mb-2">
            {/* {t('becomeMember')} */}
          </div>
          <div className="uppercase font-bold font-formula text-center text-2xl tracking-wide mb-4">
            {t('becomeMemberSubtitle')}
          </div>
          <div className="flex gap-x-2  mb-1">
            <img
              src={PointerImg}
              className="w-4 h-4 mt-1"
            />
            {t('becomeMemberPerk1')}
          </div>
          <div className="flex gap-x-2 ">
            <img
              src={PointerImg}
              className="w-4 h-4 mt-1"
            />
            {t('becomeMemberPerk2')}
          </div>
        </div>
      </div>
        <div className="flex flex-col gap-y-2">
          <InputField
            placeholder={t("firstName")}
            value={loginDetails.firstName}
            setState={() => {}}
            type="text"
            error={loginDetails.firstName.length > 0 && loginDetails.firstName.length < 2}
            handleUpdate={updateLoginDetails}
            itemKey={"firstName"}
            darkMode={darkMode}
            errorMsg="Verplicht veld."
          />
          <InputField
            placeholder={t("lastName")}
            value={loginDetails.lastName}
            setState={() => {}}
            type="text"
            error={loginDetails.lastName.length > 0 && loginDetails.lastName.length < 2}
            handleUpdate={updateLoginDetails}
            itemKey={"lastName"}
            darkMode={darkMode}
            errorMsg="Verplicht veld."
          />
        </div>
      </div>
      <Checkbox
        value={optIns.age_optin}
        onClick={() => handleOptin('age_optin')}
        darkMode={darkMode}
        text={"Ik ben jonger dan 30 jaar (als je jonger bent dan 16 jaar heb je toestemming nodig van een ouder)."}
      />
      <Checkbox
        value={optIns.terms_optin}
        onClick={() => handleOptin('terms_optin')}
        darkMode={darkMode}
        text={t('termsConditions')}
      />
      <div className="flex flex-col gap-y-4 mt-2 mb-8">
        <SmallButtonYellow
          text={t("toOrder")}
          onClick={() => handleBuySubscription()}
          darkMode={darkMode}
          disabled={checkDisabled()}
          loading={isLoadingPage}
        />
      </div> 
    </BaseModal>
  );
}  