import { BaseModal, SmallButtonYellow } from 'component-library'
import CarouselComponent from "@components/carousel/Carousel";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";

type LoginModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const LoginModalConstructor = ({ openModal, toggleModal, showCloseButton = false, darkMode = true }: LoginModalProps) => {
  const { t } = useTranslation()
  const customNavigate = useCustomNavigate();   

  return (
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      // className="overflow-hidden" 
    > 
      <div className='mt-16'>
        <CarouselComponent darkMode={darkMode}/>
      </div>
      <div className='w-full px-8 mt-12'>
        <SmallButtonYellow
          text={t('login')}
          onClick={() => customNavigate('/inloggen')}
          disabled={false}
          darkMode={darkMode}
        />
      </div>
      <div className='w-full px-8 mt-2 '> 
        <SmallButtonYellow
          text={t('createAccount')}
          onClick={() => customNavigate('/account-aanmaken')}
          disabled={false}
          darkMode={darkMode}
          inverted={true}
        />
      </div> 
    </BaseModal>
  )
}
