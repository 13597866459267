import { useEffect, useState } from "react";

import { BaseModal, InputField, SmallButtonYellow, Checkbox } from 'component-library'

import { EmailCheck } from '@regex/EmailCheck'
import { PasswordCheck } from '@regex/PasswordCheck'
import { useTranslation } from "react-i18next";
import { checkCompromisedPassword, createNewAccount } from "../api/API";
import { useUser } from "@context/UserContext";
import { useCustomNavigate } from "@components/navigate/useCustomNavigate";
import { PostalCheck } from "@regex/PostalCheck";
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useNotification } from "@context/Notification/NotificationContext";
import { InformationCircleIcon } from "@heroicons/react/16/solid";
import { CustomDatePicker } from "@components/CustomDatePicker";

type CreateAccountModalProps = {
  openModal: boolean;
  toggleModal: () => void;
  showCloseButton?: boolean;
  darkMode?: boolean;
}

export const CreateAccountModalConstructor = ({ openModal, toggleModal, darkMode = true }: CreateAccountModalProps) => {
  const { t } = useTranslation()
  const { notify } = useNotification();
  const [isLoading, setIsLoading] = useState(false)
  const customNavigate = useCustomNavigate();
  // const [dateError, setDateError] = useState(false)
  const [emailInUseError, setEmailInUseError] = useState(false)
  // const [isError, setIsError] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [errorObj, setErrorObj] = useState({
    date: false
  })
  // const [errorObj2, setErrorObj2] = useState({
  //   date: false
  // })
  // const [confirmText, setConfirmText] = useState('') 
  // const [removeSub, setRemoveSub] = useState(false)
  const checkDateOfBirth = () => {
    const [day, month, year] = loginDetails.dateOfBirth.split('-').map(Number);
    const selectedDate = new Date(year, month - 1, day);
    if(
      (selectedDate > new Date()) ||
      (selectedDate.getFullYear() < 1925) ||
      (selectedDate.getFullYear() > (new Date().getFullYear() - 12))
    ) {
      setErrorObj({
        ...errorObj,
        date: true
      })
    }
    else {
      setErrorObj({
        ...errorObj,
        date: false
      })
    }
  }
  const getToday = () => {
    let date = new Date();
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const [loginDetails, setLoginDetails] = useState({
    email: '',
    password: '',
    confirmEmail: '',
    dateOfBirth: getToday(),
    isSubscriber: false,
    postal: '',
  })
  // const { fetchUserDetails } = useUser(); 
  const [validations, setValidations] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    isLongEnough: false,
    isNotComporimised: false
  });

  const validatePassword = async(password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const isLongEnough = password.length >= 8;
    const isNotComporimised = await checkPassword()

    setValidations({
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      isLongEnough,
      isNotComporimised
    });
  };

  
  useEffect(() => {
    checkDateOfBirth()
  }, [loginDetails.dateOfBirth])

 
  const checkPassword = async() => {
    if(loginDetails.password.length < 7) return false
    let res = await checkCompromisedPassword(loginDetails.password)
    if(res.status === 200) return true
    else return false
    // return false
  }

  useEffect(() => {
    validatePassword(loginDetails.password);
  }, [loginDetails.password])
 

  const updateLoginDetails = (value: any, state: string) => {
    setLoginDetails({
      ...loginDetails,
      [state]: value
    })
  } 

  const checkDisabled = () => {
    if(!validations.isNotComporimised) return true
    if (
      loginDetails.email.length == 0 || 
      loginDetails.password.length == 0 || 
      loginDetails.confirmEmail.length == 0 ||
      loginDetails.confirmEmail !== loginDetails.email ||
      !EmailCheck(loginDetails.email) || 
      !PasswordCheck(loginDetails.password) || 
      errorObj.date || 
      loginDetails.postal.length == 0 || 
      !PostalCheck(loginDetails.postal)
    ) {
      return true
    } else {
      return false
    }
  } 

  // const formatDate = (dateStr: string) => {
  //   return dateStr.split('-').reverse().join('-');
  // };


  const handleCreateAccount = async () => { 
    setIsLoading(true)
    const data = {
      postalCode: loginDetails.postal,
      phone: '',
      parentEmail: '',
      deviceId: 'DEVICE123456',
      firstName: '',
      lastName: '',
      email: loginDetails.email,
      password: loginDetails.password,
      dateOfBirth: loginDetails.dateOfBirth,
      isSubscriber: String(loginDetails.isSubscriber)
    } 

    const response = await createNewAccount(data)
    if(response?.status === 200){ 
      //wait 50ms for DB to be finished
      // console.Console.l
      // if(response.data.message.includes("Pending")){
      //   setEmailSent(true)
      // } else {
      //   setTimeout(() => {
      //     handleLogin()
      //   }, 50) 
      // }
      setEmailSent(true)
    } else {
      if(response.data.message === "User already exists."){
        setEmailInUseError(true)
        setIsLoading(false)
      } else {
        notify(response.data.message, 'error')
        setIsLoading(false)
        console.log('error', response)
      }
    }
  }

  // const handleLogin = async () => {
  //   setIsLoading(true)  
  //   const response = await login(loginDetails.email, loginDetails.password, '')
  //   if(response.status === 429) notify('Te veel inlogpogingen, probeer het later opnieuw', 'error')
  //   if(response.status === 200){ 
  //     //wait 50ms for DB to be finished
  //     setTimeout(() => {
  //       setUserDetails() 
  //     }, 50)
  //   } else {
  //     console.log('error', response)
  //   }
  //   setIsLoading(false) 
  // }

  const updateDate = (value: any, state: string) => { 
    setLoginDetails({
      ...loginDetails,
      ['dateOfBirth']: value
    })
  }

  return(
    <BaseModal
      open={openModal}
      toggleOpen={toggleModal}
      darkMode={darkMode}
      modalTitle={t('createAccount')}
      modalSubtitle={!emailSent ? t('createAccountSubtitle') : ""}
    > 
    {!emailSent ? <>
      <div className='flex flex-col gap-y-4 mt-2 mb-8 flex-1'>
        <InputField
          placeholder={t("privateEmail")}
          value={loginDetails.email}
          setState={() => {}}
          type="text"
          error={loginDetails.email.length > 0 && !EmailCheck(loginDetails.email)}
          handleUpdate={updateLoginDetails}
          itemKey={'email'}
          darkMode={darkMode}
          errorMsg={t("emailError")}
        /> 
        {emailInUseError &&
          <div> 
            <div className="text-red-500 text-sm mt-1 flex items-center center-items gap-x-2">
              <InformationCircleIcon className="h-6 w-6 mr-1" />
              <div dangerouslySetInnerHTML={{__html: t('emailInUseError')}}>
              </div>
            </div>
          </div>
        }
        <InputField
          placeholder={t("confirmEmail")}
          value={loginDetails.confirmEmail}
          setState={() => {}}
          type="text"
          error={loginDetails.confirmEmail.length > 0 && loginDetails.confirmEmail !== loginDetails.email}
          handleUpdate={updateLoginDetails}
          itemKey={'confirmEmail'}
          darkMode={darkMode}
          errorMsg={t("confirmEmailError")}
        />
        <InputField
          placeholder={t("password")}
          value={loginDetails.password}
          setState={() => {}}
          type="password"
          error={loginDetails.password.length > 0 && !PasswordCheck(loginDetails.password)}
          handleUpdate={updateLoginDetails}
          itemKey={'password'}
          darkMode={darkMode}
          errorMsg={' '}
        />
        {(loginDetails.password.length > 0 && (!PasswordCheck(loginDetails.password) || !validations.isNotComporimised)) &&
          <div className="text-sm">
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasUpperCase ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordUpperCase")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasLowerCase ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordLowerCase")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.hasNumber ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordNumber")}
              </div>
            </div>
            <div className="flex flex-row gap-x-2">
              <div>
              {validations.isLongEnough ?
                <CheckIcon className={`w-5 h-5 text-green-500`} />
                :
                <XMarkIcon className={`w-5 h-5 text-red-500`} />
              }
              </div>
              <div>
                {t("passwordIsLongEnough")}
              </div>
            </div>
            {loginDetails.password.length > 7 &&
              <div className="flex flex-row gap-x-2">
                <div>
                {validations.isNotComporimised ?
                  <CheckIcon className={`w-5 h-5 text-green-500`} />
                  :
                  <XMarkIcon className={`w-5 h-5 text-red-500`} />
                }
                </div>
                <div>
                  {"Wachtwoord is gevonden in database met gelekte wachtwoorden."}
                </div>
              </div>
            }
          </div>
        }
        <InputField
          placeholder={t('postal')}
          value={loginDetails.postal}
          setState={() => {}}
          type="text"
          error={loginDetails?.postal.length > 0 && !PostalCheck(loginDetails.postal)}
          handleUpdate={updateLoginDetails}
          itemKey={'postal'}
          darkMode={darkMode}
          errorMsg={t('postalError')}
        />
        {/* <DateSelect
          placeholder={t("dateOfBirth")}
          value={loginDetails.dateOfBirth}
          setState={() => {}}
          type="password"
          error={false}
          handleUpdate={updateLoginDetails}
          itemKey={'dateOfBirth'}
          darkMode={darkMode}
          errorMsg={t("dateOfBirthError")}
        /> */}

        <CustomDatePicker date={loginDetails.dateOfBirth} updateDate={updateDate} errorObj={errorObj} />

        {/* <Slider
          value={loginDetails.isSubscriber}
          onClick={() => updateLoginDetails(!loginDetails.isSubscriber, 'isSubscriber')}
          darkMode={darkMode}
          text={t("discountSubscription")}
        /> */}
        <div className="pt-2">

        </div>
        <Checkbox
          value={loginDetails.isSubscriber}
          onClick={() => updateLoginDetails(!loginDetails.isSubscriber, 'isSubscriber')}
          darkMode={darkMode}
          text={t('discountSubscription')}
        />
        <div className="pt-2 text-sm" dangerouslySetInnerHTML={{__html: t('agreeTermsConditions')}}>
          {/* {t('agreeTermsConditions')} */}
        </div>
      </div>
      <div className='w-full px-8 mt-8 '>
        <SmallButtonYellow
          text={t("joinNow")}
          onClick={() => handleCreateAccount()}
          disabled={checkDisabled()}
          darkMode={darkMode}
          loading={isLoading}
        />
      </div>
    </>
    :
    <div>
      Activatie email is verzonden naar <span className="font-bold">{loginDetails.email}</span>, check je email (en spam folder!) om je account te activeren.
    </div>
    }
      <div className='w-full pt-4 px-8'>
        <div className={`text-center cursor-pointer underline ${darkMode ? "text-white" : "text-black" }`} onClick={()=>customNavigate('/inloggen')}>
          {t("back")}
        </div>
      </div>
    </BaseModal>
  )
} 
